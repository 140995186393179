import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Home = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                            <a href="/">
                                <img src={mainlogo} className="mainlogo" alt="logo" />
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0333 000 000">0333 000 000</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-md-12 col-12" >
                            <div className="content-box">
                                <h1>Road accident claims management </h1>
                                <p className="accident">Make a successful claim with the guidance of our UK-based no-fault claim experts. </p>
                                <h3 className="hr-lines">* 24/7 Dedicated Support</h3>
                                <div className="settlement">
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12" id="colborder">
                                            <div className="cta1">
                                                <h5 className="Road">Start Your Claim Today</h5>
                                                <p className="small">Reach out to us anytime. </p>
                                                <div className="box-call-body">
                                                    <a className="btn btn-circle btn-icon btn-shadow" href="tel:#">
                                                        <img src={call} className="housephone" alt="" />
                                                    </a>
                                                    <div className="box-call-info">
                                                        <h3>
                                                            <a
                                                                className="d-inline-block"
                                                                href="tel:+18007563256"
                                                            >
                                                                +1 (800) 756-32-56
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12" id="colborder">
                                            <div className="cta2">
                                                <h5 className="Road">Get Roadside Assistance</h5>
                                                <p className="small">Reach out to us anytime. </p>
                                                <div className="box-call-body">
                                                    <a className="btn btn-circle btn-icon btn-shadow" href="tel:#">
                                                        <img src={call} className="housephone" alt="" />
                                                    </a>
                                                    <div className="box-call-info">
                                                        <h3>
                                                            <a
                                                                className="d-inline-block"
                                                                href="tel: +18007563256"
                                                            >
                                                                +1 (800) 756-32-56
                                                            </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bootom-bnr">
                                <p>
                                We take your privacy seriously. For more information, please see our <span className="colorspan"><a className="contacted" href="/privacy-policy">Policy Policy</a> & <a className="contacted" href="/terms-conditions">Terms</a>.<br /> <a className="contacted" href="/Contact">Contact us </a></span>today to speak to an experienced accident claim specialist.  
                                  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <p className="colpyy">
                                © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
