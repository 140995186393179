import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call2 from "../assets/images/call2.png";
import call from "../assets/images/call.png";
import girl from "../assets/images/girl.png";
import boy from "../assets/images/boy.png";

const Home2 = () => {
    return (
        <>
            <section className="home2">
                <div className="container">
                    <div className="row" id="home-header">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="home2-mylogo">
                                <a href="/">
                                    <h3>Insurance Claim Online</h3>
                                    {/* <img src={mainlogo} className="mainlogo" alt="logo" /> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call2} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0333 000 000">0333 000 000</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center" id="home2-content">
                        <div className="col-xxl-12 col-md-12 col-12" >
                            <div className="home2-content-box">
                                <h1>No-fault accident management specialists  </h1>
                                <p className="accident">Let us know what happened, and we’ll start working right away to get maximum compensation for your claim. </p>
                                <h3 className="hr-lines">* 24/7/365 assistance available </h3>
                                <div className="Dedicated">
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>
                                
                                <div className="home2-settlement">
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12" id="colborder">
                                            <div className="home2-cta1">
                                            <img src={girl}   className="girl" alt="" />
                                                 <div className="home2-cta1-content">
                                                 <h5 className="Road">Need compensation?</h5>
                                                 <p className="small">Start your no-fault claim today.  </p>
                                                 <div className="home2-btn">
                                                 <a className="home2-call"  href="tel:+18007563256">Conatct Us</a>
                                                 </div>
                                               
                                                </div>
                                            </div>



                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12" id="colborder">
                                            <div className="home2-cta2">
                                         <div className="home2-cta1-content">
                                                 <h5 className="Road">Need recovery assistance?</h5>
                                                 <p className="small">Contact us to receive emergency roadside assistance. </p>
                                                 <div className="home2-btn">
                                                 <a className="home2-call"  href="tel:+18007563256"><span ><img src={call} className="home2call" alt="" /></span>+18007563256</a>
                                                 </div>
                                               
                                                </div>
                                                <img src={boy}   className="boy" alt="" />
                                            </div>



                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </section>
<section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/Contactus">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>

         
        </>
    );
};

export default Home2;
