import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Privacypolicy = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                            <a href="/">
                                <img src={mainlogo} className="mainlogo" alt="logo" />
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0333 000 000">0333 000 000</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Privacy Policy</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                           <h4>Privacy Policy</h4>
                           <p>We understand the importance of your privacy and how seriously you take the use and sharing of your information. We are committed to using your personal information in compliance with the UK data protection laws and this privacy policy. </p>
                           <p>This policy applies to all our websites, as well as our use of emails, text messages, and any other methods of information collection. It describes what information we collect and why, how we use it, and your rights regarding your information.</p>
                           <p>This privacy policy pertains only to personal information we hold about individuals and does not apply to information we hold about companies or other organisations.</p>
                           <h4>What information we collect </h4>
                           <p>We will only collect the information we need, including data that helps us improve our services. We may collect and process the following data about you:</p>
                           <p><strong>Personal information:</strong> This includes your name, postal address, phone number, email address, and any information you provide through forms on our website, emails, social media, or telephone. It also includes details you provide when you ask a question, give feedback, or provide information related to a claim or road traffic accident.
                            </p>
                            <p><strong>Website usage details:</strong> We collect details of your visits to our website, such as IP addresses (the location of your computer on the internet), pages accessed, and files downloaded. This information helps us determine how many people use our website, visit regularly, and find our pages popular. This statistical data about our users’ browsing actions and patterns does not identify any individual but allows us to monitor and improve our services.
                            </p>
                            <h4>How we use your information </h4>
                            <p>We may process your personal data when necessary to perform the contract we are entering into or have entered into with you, or when it is essential for our legitimate business interests. These interests include:</p>
                            <ul>
                                <li>Ensuring that content from our website is presented in the most effective manner for you and your device.</li>
                                <li>Identifying you when you contact or visit us.</li>
                                <li>Improving the quality of our products and services.</li>
                                <li>Detecting and preventing fraud and money laundering.</li>
                                <li>Conducting market research and customer surveys</li>
                            </ul>
                            <h4>With whom we share your personal information</h4>
                            <p>We may share your personal information with third parties in the following circumstances:</p>
                            <ul>
                                <li>We may disclose your data to third-party service providers who assist us in delivering our services. These may include companies involved in facilitating claims, legal representatives, medical professionals, and repair centres. </li>
                                <li>We may share your information when required to comply with legal obligations or respond to lawful requests from public authorities.</li>
                                <li>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction.</li>
                                <li>We may share your information with your consent or as otherwise disclosed at the time of data collection.</li>
                                <li>We may share your information to protect our rights and property, or the safety of our users or others.</li>
                            </ul>
                            <p>We take steps to ensure that any third parties receiving your information comply with data protection laws and treat your information securely and in accordance with our instructions.</p>
                            <h4>Security of your personal information </h4>
                            <p>We have implemented stringent security protocols and policies to manage and record your data privacy preferences. We will store your data securely to safeguard it against loss, misuse, and alteration. We ensure that any businesses with whom we share your data also maintain robust security protocols and policies to manage and record your data privacy preferences correctly, and to store your data securely.</p>
                            <p>While we make every effort to protect the security of your information during transmission over the internet, it is important to note that data transmission cannot be guaranteed to be completely secure. Despite our best efforts, we cannot ensure or guarantee the loss, misuse, or alteration of data transmitted via the Internet. </p>
                            <h4>Your rights </h4>
                            <p>Under the data protection legislation, you have certain rights regarding the personal information we hold about you. These rights include:</p>
                            <ul>
                                <li>Right to request copies of your data </li>
                                <li>Right to request rectification of your data </li>
                                <li>Right to request the erasure of your data </li>
                                <li>Right to object to the processing of your data</li>
                                <li>Right to prevent your data from being used for direct marketing </li>
                                <li>Right to request us to restrict the processing of your personal information. </li>
                            </ul>
                            <h4>Changes to privacy policy </h4>
                            <p>We may update this privacy policy periodically by publishing the amended version on our website. You’re requested to visit this page frequently to stay informed about the changes in our privacy practices. </p>
                            <h4>Contact us </h4>
                            <p>If you have any questions or concerns about our privacy practices, please contact us at <a href="/">abced@gmail.com</a>. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/Contactus">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
            {/* <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <p className="colpyy">
                            © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default Privacypolicy;
